import React from 'react';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import useLanguageSwitch from 'src/effects/useLanguageSwitch';

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: '14px 20px',
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export const LanguageSwitcher = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [languages, changeLanguage] = useLanguageSwitch();

  return (
    <List>
      {languages.map(language => (
        <MenuItem
          key={language.key}
          value={language.key}
          onClick={() => changeLanguage(language.key)}
          className={classes.menuItem}
          data-cy-list-item={camelCase(language.name)}
        >
          {t(language.name)}
        </MenuItem>
      ))}
    </List>
  );
};
