import React, { useState } from 'react';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { blueGrey, green, grey, orange, red } from '@material-ui/core/colors';

import useUserInfo from 'src/effects/useUserInfo';

const useStyles = makeStyles(theme => ({
  profile: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0 0 0',
  },
  badgeRoot: props => ({
    marginRight: props.expanded ? theme.spacing(1) : 0,
  }),
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: green[600],
  },
  awayBadge: {
    backgroundColor: orange[600],
  },
  busyBadge: {
    backgroundColor: red[600],
  },
  offlineBadge: {
    backgroundColor: grey[300],
  },
  avatar: props => ({
    width: props.expanded ? 40 : 28,
    height: props.expanded ? 40 : 28,
    cursor: 'pointer',
    transition: 'width .2s, height .2s',
  }),
  details: {
    maxWidth: 125,
    marginRight: theme.spacing(1),
  },
  userInfo: {
    color:
      theme.resources.page.home?.leftBar?.userBadge?.userInfo?.color ||
      theme.palette.primary.contrastText,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userEmail: {
    opacity: 0.7,
  },
  moreIconContainer: {
    transform: 'translate(12px, 0)',
    [theme.breakpoints.up('sm')]: {
      transform: 'translate(6px, 0)',
    },
  },
  moreIconContainerCollapsed: {
    transform: 'translate(12px, 0)',
  },
  moreIcon: {
    cursor: 'pointer',
    color:
      theme.resources.page.home?.leftBar?.userBadge?.userInfo?.color ||
      theme.palette.primary.contrastText,
    width: 16,
  },
  moreButton: {
    marginLeft: 'auto',
    color: blueGrey[200],
  },
}));

export default ({ action, expanded, className }) => {
  const classes = useStyles({ expanded });
  const userInfo = useUserInfo();
  const [status] = useState('online');
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={clsx(classes.profile, className)}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{
          root: classes.badgeRoot,
          dot: classes.badgeDot,
          badge: clsx(classes.badge, {
            [classes.onlineBadge]: status === 'online',
            [classes.awayBadge]: status === 'away',
            [classes.busyBadge]: status === 'busy',
            [classes.offlineBadge]: status === 'offline',
          }),
        }}
        variant="dot"
      >
        <Avatar alt="Person" className={classes.avatar}>
          <FaceIcon />
        </Avatar>
      </Badge>
      {expanded && (
        <div className={classes.details}>
          <Typography
            variant="h6"
            color="textPrimary"
            underline="none"
            className={classes.userInfo}
          >
            {userInfo.name}
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.userInfo, classes.userEmail)}
          >
            {userInfo.email}
          </Typography>
        </div>
      )}
      <div
        className={clsx({
          [classes.moreIconContainer]: expanded,
          [classes.moreIconContainerCollapsed]: !expanded,
        })}
      >
        <MoreVertIcon
          className={classes.moreIcon}
          onClick={event => setAnchorEl(event.currentTarget)}
          data-cy-action="more"
        />
      </div>
      {action &&
        action({
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null),
        })}
    </div>
  );
};
