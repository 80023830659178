import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Menu, MenuItem, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { camelCase } from 'lodash';

import stopPropagation from 'src/helpers/stopPropagation';
import { useGoToPolicyNew } from 'src/effects/useNavigation';
import useSchemas from 'src/effects/useSchemas';

const useStyles = makeStyles(({ spacing, resources }) => ({
  popover: {
    minWidth: 260,
    padding: spacing(2),
  },
  menuItem: {
    padding: '14px 20px',
  },
  buttonBase: {
    color: resources.page.home.leftBar.newQuote.color,
    backgroundColor: resources.page.home.leftBar.newQuote.backgroundColor,
    borderRadius: 4,
    border: resources.page.home.leftBar.newQuote.borderColor
      ? `1px solid ${resources.page.home.leftBar.newQuote.borderColor}`
      : 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default React.forwardRef(({ className, children }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const goToNewPolicy = useGoToPolicyNew();

  const [, schemas] = useSchemas();
  const enabledSchemas = schemas.filter(product => product.enabled);
  const [anchorElement, setAnchorElement] = React.useState(null);

  const openSchema = (event, schema) => {
    stopPropagation(event);
    setAnchorElement(null);
    goToNewPolicy(schema);
  };

  const hideMenu = event => {
    stopPropagation(event);
    setAnchorElement(null);
  };

  const showMenu = event => {
    const { currentTarget } = event;

    setAnchorElement(currentTarget);
  };

  return (
    <ButtonBase
      ref={ref}
      className={clsx(classes.buttonBase, className)}
      onClick={showMenu}
      data-cy-button="addNewQuote"
    >
      {children}
      {enabledSchemas && anchorElement && (
        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{ horizontal: 0, vertical: 'top' }}
          PopoverClasses={{ paper: classes.popover }}
          open={Boolean(anchorElement)}
          onClose={hideMenu}
        >
          {enabledSchemas.map((schema, index) => (
            <MenuItem
              key={`policy-schema-item-${index}`}
              value={schema}
              className={classes.menuItem}
              onClick={event => openSchema(event, schema)}
              data-cy-list-item={camelCase(schema.title)}
            >
              {t(schema.title)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </ButtonBase>
  );
});
