import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';

import stopPropagation from 'src/helpers/stopPropagation';

export const useStyles = makeStyles(({ component }) => ({
  buttonLabel: {
    textTransform: 'none',
  },
  buttonRootContained: {
    color: component.button.contained?.color || 'white',
    backgroundColor: component.button.contained?.background || 'black',
  },
  buttonRootOutlined: {
    color: component.button.outlined?.color || 'black',
    backgroundColor: component.button.outlined?.background || 'transparent',
  },
}));

export const DefaultActions = ({
  onConfirm,
  onClose,
  confirmTitle,
  closeTitle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = event => {
    if (event) stopPropagation(event);

    onClose();
  };

  const handleConfirm = event => {
    if (event) stopPropagation(event);

    onConfirm();
  };

  return (
    <>
      <Button
        onClick={handleClose}
        variant="outlined"
        classes={{
          root: classes.buttonRootOutlined,
          label: classes.buttonLabel,
        }}
        data-cy-action="cancel"
      >
        {t(closeTitle || 'Cancel')}
      </Button>
      <Button
        onClick={handleConfirm}
        autoFocus
        variant="contained"
        classes={{
          root: classes.buttonRootContained,
          label: classes.buttonLabel,
        }}
        data-cy-action="ok"
      >
        {t(confirmTitle || 'OK')}
      </Button>
    </>
  );
};
