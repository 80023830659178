import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Collapse,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout } from 'src/redux/modules/auth';

import { LanguageSwitcher } from '../LanguageSwitcher';

const useStyle = makeStyles({
  menuPaper: {
    width: 260,
    padding: 16,
    backgroundColor: '#f4f6f8',
    top: 'unset !important',
    bottom: '78px !important',
    height: 'auto !important',
  },
  menuItem: {
    padding: '14px 20px',
  },
  collapseContainer: {
    paddingLeft: 28,
  },
  opened: {
    height: 317,
  },
});

export default ({ open, anchorEl, onClose }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [openLanguage, setOpenLanguage] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleMfaClick = () => {
    history.push('/mfa/setting');
  };

  return (
    <Menu
      TransitionProps={{
        onExit: () => setOpenLanguage(false),
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      classes={{
        paper: clsx(classes.menuPaper, { [classes.opened]: openLanguage }),
      }}
    >
      <MenuItem
        className={classes.menuItem}
        onClick={() => setOpenLanguage(!openLanguage)}
        data-cy-list-item="language"
      >
        <ListItemText primary={t('Language')} />
        {openLanguage ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={openLanguage} className={classes.collapseContainer}>
        <LanguageSwitcher />
      </Collapse>
      <MenuItem
        className={classes.menuItem}
        onClick={handleMfaClick}
        data-cy-list-item="manageMfa"
      >
        {t('Manage MFA')}
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        onClick={handleLogout}
        data-cy-list-item="signOut"
      >
        {t('Sign Out')}
      </MenuItem>
    </Menu>
  );
};
